// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../.yarn/__virtual__/css-loader-virtual-215b868d6c/0/cache/css-loader-npm-6.7.1-b93a2de0d4-170fdbc630.zip/node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../.yarn/__virtual__/css-loader-virtual-215b868d6c/0/cache/css-loader-npm-6.7.1-b93a2de0d4-170fdbc630.zip/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Dpv1p{background:#fff;border:none;border-radius:var(--border-radius);-webkit-box-shadow:var(--box-shadow);box-shadow:var(--box-shadow);margin:0 0 15px;padding:15px}.KSLcO{background-color:#f5f5f5;border:none}.KSLcO,.iTIFJ{-webkit-box-shadow:none;box-shadow:none}.iTIFJ{background-color:#e4f7e8;border-color:#e4f7e8;color:#57a365}.cdgdE{background-color:#3a4759;border-color:#3a4759;color:#fff}.cdgdE,.KdblS{-webkit-box-shadow:none;box-shadow:none}.KdblS{background-color:#fff;border-color:#fff;color:#ff958f}.JAAG_{background-color:#ffeeed;border-color:#ffeeed;color:#d6625d}.JAAG_,.LStI4{-webkit-box-shadow:none;box-shadow:none}.LStI4{background:transparent;border:none;color:var(--brand-color-darkgray);font-weight:700;margin-top:100px;text-align:center}.Icz4R{border:1px solid var(--border-color);-webkit-box-shadow:none;box-shadow:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Dpv1p",
	"secondary": "KSLcO",
	"success": "iTIFJ",
	"info": "cdgdE",
	"warning": "KdblS",
	"danger": "JAAG_",
	"guide": "LStI4",
	"outlined": "Icz4R"
};
module.exports = ___CSS_LOADER_EXPORT___;
